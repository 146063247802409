<script setup lang="ts">
import { $vfm, VueFinalModal } from "vue-final-modal";
import IconArrow from "@/assets/svg/arrow.svg?component";

const { t, locale } = useI18n();

const slides = computed(() => [
    {
        img: [`/images/slides/desktop/slide1-${locale.value}.png`, `/images/slides/slide1-${locale.value}.png`],
        title: t("modal.faq.step1"),
        text: t("modal.faq.answer1"),
    },
    {
        img: [`/images/slides/desktop/slide2-${locale.value}.png`, `/images/slides/slide2-${locale.value}.png`],
        title: t("modal.faq.step2"),
        text: t("modal.faq.answer2"),
    },
    {
        img: [`/images/slides/desktop/slide3.png`, `/images/slides/slide3.png`],
        title: t("modal.faq.step3"),
        text: t("modal.faq.answer3"),
    },
    {
        img: [`/images/slides/desktop/slide4-${locale.value}.png`, `/images/slides/slide4-${locale.value}.png`],
        title: t("modal.faq.step4"),
        text: t("modal.faq.answer4"),
    },
]);

const activeSlideIndex = ref(0);
const activeSlide = computed(() => slides.value[activeSlideIndex.value]);

function onSelectSlide(idx: number) {
    if (idx > slides.value.length - 1) {
        $vfm.hide("faq");
        return;
    }
    activeSlideIndex.value = idx;
}
function onBeforeOpen() {
    activeSlideIndex.value = 0;
}
</script>

<template>
    <VueFinalModal name="faq" @before-open="onBeforeOpen">
        <ModalBase name="faq" :title="$t('modal.faq.title')">
            <div class="modal-faq">
                <picture>
                    <source :srcset="activeSlide.img[0] + '?v=2'" media="(min-width: 981px)" type="image/png" />
                    <source :srcset="activeSlide.img[1] + '?v=2'" media="(max-width: 980px)" type="image/png" />
                    <img :src="activeSlide.img[0] + '?v=2'" alt="" class="modal-faq__image" />
                </picture>
                <div class="modal-faq__step">{{ activeSlide.title }}</div>
                <div class="modal-faq__text" v-html="activeSlide.text"></div>
                <div class="modal-faq__dots">
                    <div v-for="idx in slides.length" :key="`faq-dot-${idx}`" class="modal-faq__dots-item"
                        :class="{ active: idx - 1 === activeSlideIndex }"></div>
                </div>
                <VButton variant="primary" class="modal-faq__next" @click="onSelectSlide(activeSlideIndex + 1)">
                    <template v-if="activeSlideIndex + 1 !== slides.length">
                        {{ $t("modal.faq.next") }}
                        <IconArrow />
                    </template>
                    <template v-else>{{ $t("modal.faq.close") }}</template>
                </VButton>
            </div>
        </ModalBase>
    </VueFinalModal>
</template>

<style lang="scss">
.modal-faq {
    width: 256px;
    max-width: 100%;
    color: var(--color_main__100);

    @media screen and (min-width: 980px) {
        width: 440px;
    }

    &__image {
        width: 100%;
        aspect-ratio: 280/154;
        margin-bottom: 20px;

        @media screen and (min-width: 980px) {
            aspect-ratio: 440/180;
        }
    }

    &__step {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 20px;
        min-height: 160px;

        @media screen and (min-width: 980px) {
            min-height: 130px;
        }
    }

    &__dots {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 12px;

        &-item {
            width: 28px;
            height: 5px;
            border-radius: 20px;
            background: var(--bg_light__100);
            position: relative;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #7449e6 0%, #69a4ff 98.44%);
                opacity: 0;
                transition: 0.2s;
            }

            &.active::after {
                opacity: 1;
            }
        }
    }

    &__next {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        height: 48px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
